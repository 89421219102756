import React from 'react';
import HawkTuaGame from './HawkTuaGame';

function App() {
  return (
    <div className="App">
      <h1>Hawk Tua Game</h1>
      <HawkTuaGame />
    </div>
  );
}

export default App;
import React, { useRef, useEffect, useState } from 'react';

const OrientationPrompt = () => {
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        color: 'white',
        textAlign: 'center',
        animation: 'rotate 2s infinite linear'
      }}>
        <svg width="100" height="100" viewBox="0 0 100 100">
          <path d="M10,50 L90,50 M50,10 L50,90" stroke="white" strokeWidth="5" />
          <path d="M30,30 L70,70 M30,70 L70,30" stroke="white" strokeWidth="5" />
        </svg>
        <p>Please rotate your device to landscape mode</p>
      </div>
    </div>
  );
};

const StartScreen = ({ onStart, backgroundImage, enterFullscreen, canvasSize }) => {
  return (
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: backgroundImage ? `url(${backgroundImage}) no-repeat center center / cover` : '#000000',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 10
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: `${canvasSize.height / 20}px`
      }}>
        <h1 style={{
          color: 'white',
          fontSize: `${canvasSize.height / 5}px`,
          fontWeight: 'bold',
          textAlign: 'center',
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          margin: 0
        }}>
          HAWK TUA
        </h1>
        <p style={{
          color: 'white',
          fontSize: `${canvasSize.height / 15}px`,
          fontStyle: 'italic',
          textAlign: 'center',
          marginTop: `${canvasSize.height / 40}px`,
          textShadow: '1px 1px 2px rgba(0,0,0,0.7)'
        }}>
          "spit on that thang"
        </p>
      </div>
      <button
        onClick={() => {
          enterFullscreen();
          onStart();
        }}
        style={{
          padding: '15px 30px',
          fontSize: '24px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          border: 'none',
          borderRadius: '10px',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          marginBottom: '20px'
        }}
        onMouseOver={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 1)'}
        onMouseOut={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.8)'}
      >
        Start Game
      </button>
    </div>
  );
};

const HawkTuaGame = () => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 800, height: 600 });
  const [character, setCharacter] = useState({ x: 100, y: 400, radius: 100, rotation: 0 });
  const [spit, setSpit] = useState(null);
  const [pole, setPole] = useState({ x: 700, y: 400, width: 128, height: 128 });
  const [isDragging, setIsDragging] = useState(false);
  const [power, setPower] = useState(0);
  const [angle, setAngle] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState({
    character: false,
    spit: false,
    pole: false,
    background: false
  });
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [spitSound, setSpitSound] = useState(null);
  const [backgroundSound, setBackgroundSound] = useState(null);
  const [isSoundPlaying, setIsSoundPlaying] = useState(false);
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
  const [gameStarted, setGameStarted] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [showInstructions, setShowInstructions] = useState(false);
  const [hitCount, setHitCount] = useState(0);
  const [gameOver, setGameOver] = useState(false);

  const MAX_POWER = 15;
  const SPIT_WIDTH = 40;
  const SPIT_HEIGHT = 60;
  const COLLISION_RADIUS = 60;

  useEffect(() => {
    const handleOrientation = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleOrientation);
    window.addEventListener('orientationchange', handleOrientation);

    return () => {
      window.removeEventListener('resize', handleOrientation);
      window.removeEventListener('orientationchange', handleOrientation);
    };
  }, []);

  useEffect(() => {
    const updateCanvasSize = () => {
      const container = containerRef.current;
      const width = container.clientWidth;
      const height = container.clientHeight;
      setCanvasSize({ width, height });

      const scaleX = width / 800;
      const scaleY = height / 600;
      const scale = Math.min(scaleX, scaleY);

      setCharacter(prev => ({
        ...prev,
        x: width * 0.1,
        y: height * 0.7,
        radius: 100 * scale
      }));
      
      setPole(prev => ({
        x: width * 0.9 - (128 * scale),
        y: height * 0.7 - (64 * scale),
        width: 128 * scale,
        height: 128 * scale
      }));
    };

    window.addEventListener('resize', updateCanvasSize);
    updateCanvasSize();

    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const characterImage = new Image();
    const spitImage = new Image();
    const poleImage = new Image();
    const backgroundImage = new Image();

    const loadImage = (image, key) => {
      image.onload = () => {
        console.log(`${key} image loaded successfully`);
        setImagesLoaded(prev => ({ ...prev, [key]: true }));
        if (key === 'background') {
          setBackgroundImage(image.src);
        }
      };
      image.onerror = (err) => {
        console.error(`Error loading ${key} image:`, err);
      };
    };

    loadImage(characterImage, 'character');
    loadImage(spitImage, 'spit');
    loadImage(poleImage, 'pole');
    loadImage(backgroundImage, 'background');

    characterImage.src = process.env.PUBLIC_URL + '/hawk_tua.png';
    spitImage.src = process.env.PUBLIC_URL + '/spit.png';
    poleImage.src = process.env.PUBLIC_URL + '/pole.png';
    backgroundImage.src = process.env.PUBLIC_URL + '/background.png';

    const animate = () => {
      if (!gameStarted) return;

      ctx.clearRect(0, 0, canvasSize.width, canvasSize.height);
      
      ctx.fillStyle = '#000000';
      ctx.fillRect(0, 0, canvasSize.width, canvasSize.height);

      if (imagesLoaded.background) {
        ctx.drawImage(backgroundImage, 0, 0, canvasSize.width, canvasSize.height);
      }

      ctx.save();
      ctx.fillStyle = 'white';
      ctx.font = `bold ${canvasSize.height / 5}px Arial`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'top';
      ctx.fillText('HAWK TUA', canvasSize.width / 2, canvasSize.height / 20);

      ctx.font = `italic ${canvasSize.height / 15}px Arial`;
      ctx.shadowColor = 'rgba(0, 0, 0, 0.9)';
      ctx.shadowBlur = 2;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;
      ctx.fillText('"spit on that thang"', canvasSize.width / 2, canvasSize.height / 7 + canvasSize.height / 8);
      ctx.restore();
      
      if (imagesLoaded.character) {
        ctx.save();
        ctx.translate(character.x, character.y);
        ctx.rotate(character.rotation);
        ctx.drawImage(
          characterImage,
          -character.radius,
          -character.radius,
          character.radius * 2,
          character.radius * 2
        );
        ctx.restore();
      } else {
        ctx.beginPath();
        ctx.arc(character.x, character.y, character.radius, 0, Math.PI * 2);
        ctx.fillStyle = 'red';
        ctx.fill();
      }

      if (imagesLoaded.pole) {
        ctx.save();
        ctx.translate(pole.x + pole.width / 2, pole.y + pole.height / 2);
        ctx.rotate(-Math.PI / 2);
        ctx.drawImage(poleImage, -pole.height / 2, -pole.width / 2, pole.height, pole.width);
        ctx.restore();
      } else {
        ctx.save();
        ctx.translate(pole.x + pole.width / 2, pole.y + pole.height / 2);
        ctx.rotate(-Math.PI / 2);
        ctx.fillStyle = 'gray';
        ctx.fillRect(-pole.height / 2, -pole.width / 2, pole.height, pole.width);
        ctx.restore();
      }

      if (spit) {
        if (imagesLoaded.spit) {
          ctx.save();
          ctx.translate(spit.x, spit.y);
          ctx.rotate(spit.angle + Math.PI / 2);
          ctx.drawImage(spitImage, 0, -SPIT_HEIGHT / 2, SPIT_WIDTH, SPIT_HEIGHT);
          ctx.restore();
        } else {
          ctx.beginPath();
          ctx.arc(spit.x, spit.y, SPIT_WIDTH / 4, 0, Math.PI * 2);
          ctx.fillStyle = 'white';
          ctx.fill();
        }
      }

      ctx.fillStyle = 'white';
      ctx.font = '24px Arial';
      ctx.textAlign = 'left';
      ctx.fillText(`Hits: ${hitCount}/3`, 20, 30);

      if (gameOver) {
        ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
        ctx.fillRect(0, 0, canvasSize.width, canvasSize.height);
        ctx.fillStyle = 'white';
        ctx.font = `bold ${canvasSize.height / 10}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText("Congrats, You're GAY!!!", canvasSize.width / 2, canvasSize.height / 2);
      }

      requestAnimationFrame(animate);
    };

    animate();
  }, [gameStarted, canvasSize, character, spit, pole, imagesLoaded, SPIT_WIDTH, SPIT_HEIGHT, hitCount, gameOver]);
  
  useEffect(() => {
    if (spit) {
      const animateSpit = () => {
        setSpit(prev => {
          if (!prev) return null;
          const newX = prev.x + prev.velocityX;
          const newY = prev.y + prev.velocityY;
          
          if (newY > canvasSize.height || newX < 0 || newX > canvasSize.width || newY < 0) {
            return null;
          }
          
          const poleCenterX = pole.x + pole.width / 2;
          const poleCenterY = pole.y + pole.height / 2;
          const dx = newX - poleCenterX;
          const dy = newY - poleCenterY;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < COLLISION_RADIUS || 
              (newX > pole.x && newX < pole.x + pole.width && 
               newY > pole.y && newY < pole.y + pole.height)) {
            console.log('Collision detected!');
            const newHitCount = hitCount + 1;
            setHitCount(newHitCount);
            if (newHitCount >= 3) {
              setGameOver(true);
            }
            return null;
          }
          
          const newAngle = Math.atan2(prev.velocityY, prev.velocityX);
          
          return {
            ...prev,
            x: newX,
            y: newY,
            velocityY: prev.velocityY + 0.2,
            angle: newAngle
          };
        });
      };

      const animationId = requestAnimationFrame(animateSpit);
      return () => cancelAnimationFrame(animationId);
    }
  }, [spit, canvasSize, pole, COLLISION_RADIUS, hitCount]);

  useEffect(() => {
    const audio = new Audio(process.env.PUBLIC_URL + '/spit_sound.mp3');
    audio.load();
    setSpitSound(audio);
  }, []);

  useEffect(() => {
    const audio = new Audio(process.env.PUBLIC_URL + '/crowd.mp3');
    audio.loop = true;
    setBackgroundSound(audio);

    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, []);

  useEffect(() => {
    if (gameStarted && backgroundSound && !isSoundPlaying) {
      backgroundSound.play().then(() => {
        setIsSoundPlaying(true);
      }).catch(error => console.error("Error playing background sound:", error));
    } else if (!gameStarted && backgroundSound && isSoundPlaying) {
      backgroundSound.pause();
      backgroundSound.currentTime = 0;
      setIsSoundPlaying(false);
    }
  }, [gameStarted, backgroundSound, isSoundPlaying]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const enterFullscreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current.requestFullscreen().then(() => {
        setIsFullscreen(true);
      }).catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message}`);
      });
    }
  };

  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      }).catch(err => {
        console.error(`Error attempting to exit full-screen mode: ${err.message}`);
      });
    }
  };

  const toggleFullscreen = () => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };

  const handleStart = (e) => {
    if (!gameStarted || gameOver) return;
    const { clientX, clientY } = e.touches ? e.touches[0] : e;
    const rect = e.target.getBoundingClientRect();
    const x = (clientX - rect.left) * (canvasSize.width / rect.width);
    const y = (clientY - rect.top) * (canvasSize.height / rect.height);
    setIsDragging(true);
    setPower(0);
  };

  const handleMove = (e) => {
    if (!gameStarted || !isDragging || gameOver) return;
    const { clientX, clientY } = e.touches ? e.touches[0] : e;
    const rect = e.target.getBoundingClientRect();
    const x = (clientX - rect.left) * (canvasSize.width / rect.width);
    const y = (clientY - rect.top) * (canvasSize.height / rect.height);
    const dx = x - character.x;
    const dy = y - character.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const newPower = Math.min(distance / (canvasSize.width / (MAX_POWER * 1.5)), MAX_POWER);
    const newAngle = Math.atan2(dy, dx);
    setPower(newPower);
    setAngle(newAngle);
    
    setCharacter(prev => ({
      ...prev,
      rotation: newAngle
    }));
  };

  const handleEnd = () => {
    if (!gameStarted || !isDragging || gameOver) return;
    setIsDragging(false);
    launchSpit();
  };

  const launchSpit = () => {
    const speedMultiplier = 1.25;
    const velocityX = Math.cos(angle) * power * 0.8 * speedMultiplier;
    const velocityY = Math.sin(angle) * power * 0.8 * speedMultiplier;
    setSpit({
      x: character.x,
      y: character.y,
      velocityX,
      velocityY,
      angle: angle
    });
    setPower(0);

    if (spitSound) {
      spitSound.currentTime = 0;
      spitSound.play().catch(error => console.error("Error playing sound:", error));
    }
  };

  const handleStartGame = () => {
    setGameStarted(true);
    setHitCount(0);
    setGameOver(false);
    setShowInstructions(true);
    setTimeout(() => setShowInstructions(false), 9000);
  };

  const handlePlayAgain = () => {
    setGameStarted(true);
    setHitCount(0);
    setGameOver(false);
    setShowInstructions(true);
    setSpit(null);
    setPower(0);
    setAngle(0);
    setCharacter(prev => ({
      ...prev,
      rotation: 0
    }));
    setTimeout(() => setShowInstructions(false), 9000);
  };

  return (
    <div 
      ref={containerRef} 
      style={{ 
        width: '100vw', 
        height: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#000',
        position: 'relative'
      }}
    >
      {!isLandscape && <OrientationPrompt />}
      {!gameStarted && (
        <StartScreen 
          onStart={handleStartGame} 
          backgroundImage={backgroundImage} 
          enterFullscreen={enterFullscreen}
          canvasSize={canvasSize}
        />
      )}
      <canvas
        ref={canvasRef}
        width={canvasSize.width}
        height={canvasSize.height}
        onMouseDown={handleStart}
        onMouseMove={handleMove}
        onMouseUp={handleEnd}
        onMouseLeave={handleEnd}
        onTouchStart={handleStart}
        onTouchMove={handleMove}
        onTouchEnd={handleEnd}
        style={{ 
          touchAction: 'none',
          width: '100%',
          height: '100%',
          objectFit: 'contain'
        }}
      />
      {showInstructions && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          padding: '20px',
          borderRadius: '10px',
          textAlign: 'center',
          fontSize: `${canvasSize.height / 20}px`,
          animation: 'fadeInOut 3s forwards'
        }}>
          Spit on that thang 3 times!         
          Drag finger to play.
        </div>
      )}
      {gameOver && (
        <button 
          onClick={handlePlayAgain}
          style={{
            position: 'absolute',
            top: 'calc(50% + 80px)',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '15px 30px',
            fontSize: '24px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            border: 'none',
            borderRadius: '10px',
            cursor: 'pointer',
            transition: 'background-color 0.3s'
          }}
        >
          Play Again?
        </button>
      )}
      <button 
        onClick={toggleFullscreen}
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          padding: '10px',
          background: 'rgba(255, 255, 255, 0.5)',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
      >
        {isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
      </button>
    </div>
  );
};

export default HawkTuaGame;